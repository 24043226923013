//Importando variables y mixins a utilizar
@use "../base/variables" as v;
@use "../base/mixins" as m;

.informacion{

    display: flex;
    flex-direction: column;
    align-items: center;

    @include m.desktop{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.boton{
    background-color: v.$cafe;
    color: v.$blanco;
    border: none;
    padding: 1rem 2rem;
    border-radius: 1rem;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out, font-size .2s ease-in-out;

    &:hover{
        cursor: pointer;
        background-color: darken(v.$cafe, 15%);
        font-size: 2rem;
    }

    margin-top: 1.5rem;
}

.error, .correcto{
    text-transform: uppercase;
    text-align: center;
    font-size: 1.8rem;
    margin: 2rem auto;
    color: v.$blanco;
    padding: 1.5rem 0;
    font-weight: bold;
}

.error{
    background-color: v.$rojo;
}

.correcto{
    background-color: v.$verde;
}

.formulario-admin{
    width: min(90%, 130rem);
    margin: 2rem auto;
    background-color: v.$gris;
    padding: 2rem;
    border-radius: 1rem;

    label{
        display: block;
        margin: .5rem 0;
    }

    legend{
        display: block;
        text-transform: uppercase;
        text-align: center;
    }
}

.formulario-bomberos{

    @include m.desktop{
        @include m.grid(2, 1rem);
    }
}

.turnos-grid{
    display: flex;
    flex-direction: column;
    align-items: center;

    @include m.desktop{
        flex-direction: row;
        justify-content: space-around;
    }
}

.quiubolesque{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

#mapDiv{
    width: 100%;
    height: 400px;
}

.imagenServicio{
    max-height: 50rem;
    max-width: 100%;
    margin: 0 auto;
    border-radius: 1rem;
    display: block;
}

.tres-columnas{

    background-color: v.$blanco;
    border-radius: 1rem;
    padding: 1rem;

    @include m.desktop{
        @include m.grid(3, 1.5rem);
    }
    
}

.serviciosderivados{
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
}