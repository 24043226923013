//Importando variables y mixins a utilizar
@use "../base/variables" as v;
@use "../base/mixins" as m;

.header{
    text-align: center;
    img{
        height: 20rem;
    }
    h1{
        text-transform: uppercase;
    }
}

.shadow{
    -webkit-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.27);
    -moz-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.27);
    box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.27);
    background-color: v.$blanco;
    padding: 2rem;
    border-radius: 1.5rem;
}

.formulario{
    width: min(90%, 60rem);
    margin: 0 auto;
    background-color: v.$gris;
    padding: 2rem;
    border-radius: 1rem;
    min-height: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label{
        display: block;
        margin: .5rem 0;
    }
}

.boton-inicio{
    width: 100%;
    background-color: v.$cafe;
    color: v.$blanco;
    border: none;
    padding: 1rem 2rem;
    border-radius: 1rem;
    text-transform: uppercase;
    transition: background-color .2s ease-in-out, font-size .2s ease-in-out;

    &:hover{
        cursor: pointer;
        background-color: darken(v.$cafe, 15%);
        font-size: 2rem;
    }

    @include m.desktop{
        display: block;
        margin: 0 auto;
        width: auto;
    }

}

.boton-salir{
    display: inline-block;
    margin: 2rem 0;
    background-color: rgb(148, 15, 15);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 1rem;
}

.boton-excel{
    display: inline-block;
    margin: 2rem 0;
    background-color: rgb(15, 148, 39);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 1rem 2rem;
    border-radius: 1rem;
}

.icono-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.formulario-content{
    margin: 2rem auto;
    width: 100%;
}

.formulario-input{
    width: 100%;
    border-radius: 1rem;
    border: none;
    padding: 1rem 1.5rem;
}

.footer{
    padding: 1.5rem 0;
    color: v.$blanco;
    background-color: v.$cafe;
    margin-top: 2rem;
    text-align: center;
    font-size: 2rem;
}