//Fuente principal a utilzar en el proyecto
$fuentePrincipal : 'Open Sans', sans-serif;

//Paleta de colores a utilizar en el proyecto
$blanco : white;
$oscuro : black;
$gris : rgb(230, 230, 230);
$grisOscuro : rgb(211, 211, 211);
$cafe : rgb(175, 130, 79);
$verde : rgb(60, 116, 60);
$rojo : rgb(238, 85, 85);