//Importando variables y mixins a utilizar
@use "../base/variables" as v;
@use "../base/mixins" as m;

.tarjeta{
    width: min(95%, 60rem);
    margin: 1rem auto;
    padding: 1.5rem;
    border-radius: 1.5rem;
    background-color: v.$gris;
}

.header-tarjeta{
    
    img{
        display: block;
        margin: 0 auto;
        max-height: 18rem;
        max-width: 18rem;
    }

    @include m.desktop{
        display: grid;
        grid-template-columns: 30% 70%;

        img{
            margin: 0;
        }
    }
}

.textUppercase{
    text-transform: uppercase;
}

.centrar{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header-personalinfo{

    margin-top: 1.5rem;

    img{
        width: 18rem;
        height: 18rem;
        border-radius: 50%;
        display: block;
        margin: 0 auto;
    }

    @include m.desktop{
        @include m.grid(2, 1rem);

        img{
            margin: 0;
        }
    }
}

.informacionbombero{

    h3{
        text-align: center;
    }

    @include m.desktop{
        h3{
            text-align: left;
        }
    }
}

.tabla{
    width: 100%;
    margin: 1rem auto;
    padding: 1rem;
    border-radius: 1rem;
    border: 2px solid v.$oscuro;
}

.fila{
    display: block;
    width: 100%;
    margin-bottom: 3rem;
}

.agregar{
    background-color: rgb(56, 148, 184);
    border-radius: 50%;
    border: none;

    &:hover{
        background-color: darken(rgb(56, 148, 184), 10%);
        cursor: pointer;
    }
}

.iconocamara{
    display: inline-block;
    background-color: v.$blanco;
    border-radius: 50%;
    margin: 2rem 0;
    height: 7rem;
    width: 7rem;
    border: none;


    &:hover{
        cursor: pointer;
    }
}

.ocultar{
    display: none;
}

.boton-servicio{
    margin: 1rem auto;
    display: block;
    text-align: center;
    background-color: green;
    border: none;
    color: v.$blanco;
    text-transform: uppercase;
    font-weight: bold;

    &:hover{
        cursor: pointer;
    }
}

.fotoservicio{
    display: block;
    margin: 1rem auto;
    max-width: 100%;
    border-radius: 1.5rem;
}