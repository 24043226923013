//Importando variables y mixins a utilizar
@use "../base/variables" as v;
@use "../base/mixins" as m;

.botones-grid{
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;

    @include m.desktop{
        flex-direction: row;
    }
}

.boton-ver{
    background-color: v.$cafe;
    color: v.$blanco;
    text-transform: uppercase;
    border: none;
    padding: 1rem 2rem;
    border-radius: 1rem;

    &:hover{
        cursor: pointer;
    }
}