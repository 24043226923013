//Importando variables y mixins a utilizar
@use "../base/variables" as v;
@use "../base/mixins" as m;

.cuatro-columnas{
    display: flex;
    flex-direction: column;
    align-items: center;

    @include m.desktop{
        flex-direction: row;
        justify-content: space-between;
        gap: 1.5rem;
    }
}

.tiposervicio{
    width: 100%;
    text-align: center;

    input{
        opacity: 0;
    }
}

.label-radio{
    font-weight: bold;
    display: block;
    background-color: v.$grisOscuro;
    width: 100%;
    padding: 2rem 0;

    &:hover{
        cursor: pointer;
        background-color: darken(v.$grisOscuro, 10%);
    }

    &:active{
        background-color: darken(v.$grisOscuro, 35%);
    }
}
.label-check{
    font-weight: bold;
    display: block;
    background-color: v.$grisOscuro;
    width: 100%;
    padding: 2rem 0;

    &:hover{
        cursor: pointer;
        background-color: darken(v.$grisOscuro, 10%);
    }

    &:active{
        background-color: darken(v.$grisOscuro, 35%);
    }
}

#radio1:checked ~ .label-radio, #radio2:checked ~ .label-radio, #radio3:checked ~ .label-radio,
#radio4:checked ~ .label-radio, #radio5:checked ~ .label-radio, #radio6:checked ~ .label-radio,
#radio7:checked ~ .label-radio, #radio8:checked ~ .label-radio, #radio9:checked ~ .label-radio,
#radio10:checked ~ .label-radio, #radio11:checked ~ .label-radio, #radio12:checked ~ .label-radio,
#radio13:checked ~ .label-radio, #radio14:checked ~ .label-radio, #radio15:checked ~ .label-radio,
#radio16:checked ~ .label-radio, #radio17:checked ~ .label-radio, #radio18:checked ~ .label-radio,
#radio19:checked ~ .label-radio, #radio20:checked ~ .label-radio, #radio21:checked ~ .label-radio,
#radio22:checked ~ .label-radio, #radio23:checked ~ .label-radio, #radio24:checked ~ .label-radio,
#radio25:checked ~ .label-radio, #radio26:checked ~ .label-radio, #radio27:checked ~ .label-radio,
#radio28:checked ~ .label-radio, #radio29:checked ~ .label-radio, #radio30:checked ~ .label-radio{
    background-color: darken(v.$grisOscuro, 20%);
}

#lugarServicio1:checked ~ .label-radio, #lugarServicio2:checked ~ .label-radio, #lugarServicio3:checked ~ .label-radio,
#lugarServicio4:checked ~ .label-radio, #lugarServicio5:checked ~ .label-radio, #lugarServicio6:checked ~ .label-radio,
#lugarServicio7:checked ~ .label-radio, #lugarServicio8:checked ~ .label-radio, #lugarServicio9:checked ~ .label-radio,
#lugarServicio10:checked ~ .label-radio, #lugarServicio11:checked ~ .label-radio, #lugarServicio12:checked ~ .label-radio,
#lugarServicio13:checked ~ .label-radio, #lugarServicio14:checked ~ .label-radio, #lugarServicio15:checked ~ .label-radio,
#lugarServicio16:checked ~ .label-radio, #lugarServicio17:checked ~ .label-radio, #lugarServicio18:checked ~ .label-radio,
#lugarServicio19:checked ~ .label-radio, #lugarServicio20:checked ~ .label-radio, #lugarServicio21:checked ~ .label-radio,
#lugarServicio22:checked ~ .label-radio, #lugarServicio23:checked ~ .label-radio, #lugarServicio24:checked ~ .label-radio{
    background-color: darken(v.$grisOscuro, 20%);
}

#radioCheck1:checked ~ .label-check, #radioCheck2:checked ~ .label-check, #radioCheck3:checked ~ .label-check,
#radioCheck4:checked ~ .label-check, #radioCheck5:checked ~ .label-check, #radioCheck6:checked ~ .label-check,
#radioCheck7:checked ~ .label-check, #radioCheck8:checked ~ .label-check, #radioCheck9:checked ~ .label-check,
#radioCheck10:checked ~ .label-check, #radioCheck11:checked ~ .label-check, #radioCheck12:checked ~ .label-check,
#radioCheck13:checked ~ .label-check, #radioCheck14:checked ~ .label-check, #radioCheck15:checked ~ .label-check,
#radioCheck16:checked ~ .label-check, #radioCheck17:checked ~ .label-check, #radioCheck18:checked ~ .label-check,
#radioCheck19:checked ~ .label-check, #radioCheck20:checked ~ .label-check, #radioCheck21:checked ~ .label-check,
#radioCheck22:checked ~ .label-check, #radioCheck23:checked ~ .label-check, #radioCheck24:checked ~ .label-check,
#radioCheck25:checked ~ .label-check, #radioCheck26:checked ~ .label-check, #radioCheck27:checked ~ .label-check,
#radioCheck28:checked ~ .label-check, #radioCheck29:checked ~ .label-check, #radioCheck30:checked ~ .label-check{
    background-color: darken(v.$grisOscuro, 20%);
}

ul{
    list-style: none;
    width: 100%;
    height: auto;
    margin-top: 0;
}
li{
    background-color: v.$blanco;
    width: 100%;
    padding: 1rem;
    float: left;
    cursor: pointer;
}

.btn-Propagacion{
    margin: 1rem auto;
}

.bordes{
    border-top: 3px solid black;
    border-bottom: 3px solid black;
}

.bordes-abajo{
    border-bottom: 3px solid black;
}

.max-width{
    width: 100%;
}

.fondo-rojo{
    background-color: v.$rojo;
}

.botonHojaServicio{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    background-color: v.$verde;
    border: none;
    color: v.$blanco;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 1rem;
    padding: 1rem 2rem;
    cursor: pointer;

    @include m.desktop{
        flex-direction: row;
    }
}

.bordesAfectados{
    @include m.desktop{
        padding: 0 1rem;
        border-left: 2px solid black;
        border-right: 2px solid black;
    }
}

.dividir2{
    display: flex;
    justify-content: space-around;
    width: auto;
    text-align: center;
}

//Datos para hoja de estilos de la tabla
.tabla{
    width: 100%;
    margin: 2rem auto;
    border: none;
}

.encabezado-tabla{
    background-color: gray;
    margin: 0;
}
.encabezados{
    padding: 1rem 0;
    border: 1px solid black;
    text-transform: uppercase;
    text-align: center;
}
.navegacion-paginas{
    display: block;
    margin: 2.5rem 0;
}
.tabla-content{
    tr:nth-of-type(odd){
        background-color: rgb(217, 217, 217);
    }
}
.lista-paginas{
    display: flex;
    justify-content: left;
}
.paginas{
    display: block;
    width: auto;
    border: 1px solid black;
}
.pagina{
    display: block;
}
.pagina-activa{
    background-color: gray;
}
.linea-tabla{
    text-align: center;
    text-transform: uppercase;
    min-height: 2.5rem;
    padding: 2.5rem 0;

    td{
        padding: 1.5rem 0;
    }
}
.busqueda-tabla{
    margin: 1rem 0px;
}