@mixin desktop{
    @media(min-width: 768px){
        @content;
    }
}

@mixin grid($columnas, $separacion){
    display: grid;
    grid-template-columns: repeat($columnas, 1fr);
    gap: $separacion;
}